.panel {
    position: absolute;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    z-index: 2;
    opacity: 0;
    transition: opacity .3s;
    -webkit-transition: opacity 0.3s;
}
.panel.left {
    left: 0;
}
.panel.right {
    right: 0;
}
.panel.visible {
    background-color: white;
    opacity: 1;
}
.panel.left.visible {
    border-right: 1px solid rgb(200,200,200);
    box-shadow: 2px 2px 4px rgba(0,0,0,.2)
}
.panel.right.visible {
    border-left: 1px solid rgb(200,200,200);
    box-shadow: -2px 2px 4px rgba(0,0,0,.2)
}
@media all and (min-width: 801px) {
    .panel {
        max-width: 33%;
    }
    .panel.visible {
        border-radius: 5px;
    }
    .panel.left.visible {
        min-width: 300px;
    }
    .panel.right.visible {
        min-width: 300px;
    }
}
@media all and (max-width: 800px) {
    .panel {
        max-width: 100%;
    }
}