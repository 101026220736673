html,
body,
#root,
.App,
.map {
  width: 100%;
  height: 100%;
  margin: 0px;
}

.esri-view .esri-view-surface--inset-outline:focus::after {
  outline: none !important;
}

.Title {
  position: fixed;
  left: 60px;
  top: 15px;
  color: #fff;
  font-family: "Avenir Next W00", "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 60px;
  background-color: #1a3664;
  font-size: 20px;
  z-index: 1;
  display: flex;
  align-items: center;
}

.panelText {
  font-size: 40px;
  margin: 5px 10px 0;
}
.panelSearch {
  float: left;
  display: inline-block;
  margin: 0px 10px 0;
}
